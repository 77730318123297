import { RGBColor } from 'react-color'

export function rgbaFromRGBColor (color: RGBColor): string {
  const { r, g, b, a } = color
  return `rgba(${r}, ${g}, ${b}, ${a})`
}

export function isSameRGBColor (colorA: RGBColor, colorB: RGBColor) {
  return colorA.r === colorB.r &&
    colorA.g === colorB.g &&
    colorA.b === colorB.b &&
    colorA.a === colorB.a
}
