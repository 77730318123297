import React, { Component, ReactNode } from 'react'
import { Color, ColorChangeHandler, RGBColor, SketchPicker } from 'react-color'
import OutsideClickHandler from 'react-outside-click-handler'
import './ColorPickButton.scss'
import { rgbaFromRGBColor } from '../lib/Colors'

interface Props {
  children: ReactNode
  color: Color
  onChange?: ColorChangeHandler
}

interface State {
  isOpenColorPicker: boolean
}

export class ColorPickButton extends Component<Props, State> {
  state: State = {
    isOpenColorPicker: false
  }

  handleClickButton = () => {
    this.setState({ isOpenColorPicker: true })
  }

  handleColorPickerOutsideClick = () => {
    this.setState({ isOpenColorPicker: false })
  }

  render () {
    const { children, onChange, color } = this.props
    const { isOpenColorPicker } = this.state
    return (
      <>
        <div className='color-pick-button'>
          <button
            onClick={this.handleClickButton}
            style={{ background: rgbaFromRGBColor(color as RGBColor) }}
          >
            {children}
          </button>
        </div>
        <OutsideClickHandler onOutsideClick={this.handleColorPickerOutsideClick}>
          <div style={{ display: isOpenColorPicker ? 'block' : 'none' }}>
            <SketchPicker color={color} onChange={onChange} />
          </div>
        </OutsideClickHandler>
      </>
    )
  }
}
