import React from 'react'
// import logo from '../logo.svg'
import './App.scss'
import { Stages } from './Stages'

function App () {
  return (
    <div className='app'>
      <div>
        <header>
          <h1><a href='/'>SHADOWIMG</a></h1>
          <div />
        </header>
        <main>
          <Stages />
        </main>
        <footer>
          <div className='info'>
            <a href='/licenses.html' target='_blank' rel='noopener noreferrer'>Licenses</a>&nbsp;
            <a href='/privacy.html' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>
          </div>
          <div />
          <address>
            <a href='https://twitter.com/akiroom' target='_blank' rel='noopener noreferrer'>
              @akiroom
            </a>
          </address>
        </footer>
      </div>
    </div>
  )
}

export default App
