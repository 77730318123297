import React, { Component } from 'react'
import { RGBColor } from 'react-color'
import { isEqual } from 'lodash-es'
import { rgbaFromRGBColor } from '../lib/Colors'

interface Props {
  imageElementId: string
  backgroundColor: RGBColor
  shadowColor: RGBColor
  shadowBlur: number
  shadowOffsetX: number
  shadowOffsetY: number
}
interface State {
  resultImageSource: string | undefined
}

export class ShadowedImage extends Component<Props, any> {
  state: State = {
    resultImageSource: undefined
  }

  componentDidMount () {
    this.updateImage()
  }

  componentDidUpdate (prevProps: Readonly<Props>) {
    if (!isEqual(prevProps, this.props)) {
      this.updateImage()
    }
  }

  updateImage () {
    const {
      imageElementId,
      backgroundColor,
      shadowColor,
      shadowBlur,
      shadowOffsetX,
      shadowOffsetY
    } = this.props
    const imageElement = document.getElementById(imageElementId) as HTMLImageElement | null
    if (!imageElement) {
      return
    }
    const { naturalWidth, naturalHeight } = imageElement
    const width = naturalWidth + Math.abs(shadowOffsetX) + shadowBlur * 2
    const height = naturalHeight + Math.abs(shadowOffsetY) + shadowBlur * 2

    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    const context = canvas.getContext('2d')
    if (!context) {
      return
    }

    // 背景色を塗る
    context.fillStyle = rgbaFromRGBColor(backgroundColor)
    context.fillRect(0, 0, width, height)

    // 影を設定
    context.shadowOffsetX = shadowOffsetX
    context.shadowOffsetY = shadowOffsetY
    context.shadowColor = rgbaFromRGBColor(shadowColor)
    context.shadowBlur = shadowBlur

    const left = shadowOffsetX - shadowBlur
    const top = shadowOffsetY - shadowBlur

    // 画像を描画
    const drawPosX = left >= 0 ? 0 : Math.abs(left)
    const drawPosY = top >= 0 ? 0 : Math.abs(top)
    context.drawImage(imageElement, drawPosX, drawPosY)

    // 出力
    const resultImageSource = canvas.toDataURL('image/png')
    this.setState({ resultImageSource })
  }

  render () {
    const { resultImageSource } = this.state
    return <img src={resultImageSource} alt='' />
  }
}
