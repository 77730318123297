import React, { ChangeEvent, Component, SyntheticEvent } from 'react'
import { ColorResult, RGBColor } from 'react-color'
import { readAsDataURL } from '../lib/AsyncFileReader'
import { ShadowedImage } from './ShadowedImage'
import { ColorPickButton } from './ColorPickButton'
import { isSameRGBColor } from '../lib/Colors'

interface Props {}
interface State {
  imageSources: string[],
  loadedPreviewIds: string[]
  backgroundColor: RGBColor
  shadowColor: RGBColor
  shadowBlur: number
  shadowOffsetX: number
  shadowOffsetY: number
}

interface OptionParams {
  br?: number
  bg?: number
  bb?: number
  ba?: number
  sr?: number
  sg?: number
  sb?: number
  sa?: number
  sblur?: number
  sox?: number
  soy?: number
}

const initialState: State = {
  imageSources: [],
  loadedPreviewIds: [],
  backgroundColor: {
    r: 255,
    g: 255,
    b: 255,
    a: 1
  },
  shadowColor: {
    r: 0,
    g: 0,
    b: 0,
    a: 1
  },
  shadowBlur: 10,
  shadowOffsetX: 5,
  shadowOffsetY: 5
}

export class Stages extends Component<Props, State> {
  state: State = initialState

  componentDidMount () {
    const params = Object.fromEntries(Array.from(new URLSearchParams(window.location.search).entries()).map((entry) => [
      entry[0],
      parseFloat(entry[1])
    ]))
    const {
      br,
      bg,
      bb,
      ba,
      sr,
      sg,
      sb,
      sa,
      sblur,
      sox,
      soy
    } = params

    this.updateStateWithQueryParamsColorIfNeeded('backgroundColor', {
      r: br,
      g: bg,
      b: bb,
      a: ba
    })
    this.updateStateWithQueryParamsColorIfNeeded('shadowColor', {
      r: sr,
      g: sg,
      b: sb,
      a: sa
    })
    this.updateStateWithQueryParamsNumberIfNeeded('shadowBlur', sblur)
    this.updateStateWithQueryParamsNumberIfNeeded('shadowOffsetX', sox)
    this.updateStateWithQueryParamsNumberIfNeeded('shadowOffsetY', soy)
  }

  updateStateWithQueryParamsColorIfNeeded (key: 'backgroundColor' | 'shadowColor', { r, g, b, a }: {r?: number, g?: number, b?: number, a?: number}) {
    if (r || g || b || a) {
      const initColor = initialState[key]
      const newColor = {
        r: r || initColor.r,
        g: g || initColor.g,
        b: b || initColor.b,
        a: a || initColor.a
      }
      const state: {} = { [key]: newColor }
      this.setState(state)
    }
  }

  updateStateWithQueryParamsNumberIfNeeded (key: 'shadowBlur' | 'shadowOffsetX' | 'shadowOffsetY', value: number) {
    if (value && value !== initialState[key]) {
      const state: {} = { [key]: value }
      this.setState(state)
    }
  }

  async openFiles (files: FileList) {
    const results: Promise<unknown>[] = []
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i]
      console.log(file)
      results.push(readAsDataURL(file))
    }

    const wholeResults = await Promise.all(results) as string[]
    this.setState({ loadedPreviewIds: [],
      imageSources: wholeResults })
  }

  handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const inputElement = e.currentTarget as HTMLInputElement
    const { files } = inputElement
    if (!files || files.length === 0) {
      return
    }

    this.openFiles(files)
  }

  handlePreviewImageLoad = (e: SyntheticEvent<HTMLImageElement>) => {
    const { id } = e.currentTarget
    this.setState({ loadedPreviewIds: this.state.loadedPreviewIds.concat([id]) })
  }

  handleColorChange = (key: keyof State, color: ColorResult) => {
    this.setState({ [key]: color.rgb } as unknown as Pick<State, keyof State>, this.replaceHistoryWithState)
  }

  handleNumbersChange = (key: keyof State, e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    const num = parseInt(value, 10)
    this.setState({ [key]: isNaN(num) ? 0 : num } as unknown as Pick<State, keyof State>, this.replaceHistoryWithState)
  }

  replaceHistoryWithState = () => {
    const { backgroundColor, shadowColor, shadowBlur, shadowOffsetX, shadowOffsetY } = this.state
    let params: OptionParams = {}
    if (!isSameRGBColor(initialState.backgroundColor, backgroundColor)) {
      params = Object.assign(params, {
        br: backgroundColor.r,
        bg: backgroundColor.g,
        bb: backgroundColor.b,
        ba: backgroundColor.a
      })
    }
    if (!isSameRGBColor(initialState.shadowColor, shadowColor)) {
      params = Object.assign(params, {
        sr: shadowColor.r,
        sg: shadowColor.g,
        sb: shadowColor.b,
        sa: shadowColor.a
      })
    }
    if (initialState.shadowBlur !== shadowBlur) {
      params.sblur = shadowBlur
    }
    if (initialState.shadowOffsetX !== shadowOffsetX) {
      params.sox = shadowOffsetX
    }
    if (initialState.shadowOffsetY !== shadowOffsetY) {
      params.soy = shadowOffsetY
    }
    const queryString = Object.keys(params).map((key) => `${key}=${params[key as keyof OptionParams]}`).join('&')
    window.history.replaceState(null, document.title, queryString ? `/?${queryString}` : '/')
  }

  render () {
    const {
      imageSources,
      loadedPreviewIds,
      backgroundColor,
      shadowColor,
      shadowBlur,
      shadowOffsetX,
      shadowOffsetY
    } = this.state
    return (
      <div className='stages'>
        <div className='import-stage'>
          <h2>{`1.Load ${imageSources.length > 1 ? 'them' : 'it'}`}</h2>
          <div className='form'>
            <input type='file' accept='image/*' onChange={this.handleChangeFile} multiple />
          </div>
          <div className='previews'>
            {
              imageSources.map((source, key) => <div key={key}><img id={`preview-${key}`} src={source} onLoad={this.handlePreviewImageLoad} alt='' /></div>)
            }
          </div>
        </div>
        <div className='operation-stage'>
          <h2>2. Process</h2>
          <div className='operation-fields'>
            <div>
              <label>Background color</label>
              <ColorPickButton color={backgroundColor} onChange={(color) => this.handleColorChange('backgroundColor', color)}>
              </ColorPickButton>
            </div>
            <div>
              <label>Shadow color</label>
              <ColorPickButton color={shadowColor} onChange={(color) => this.handleColorChange('shadowColor', color)}>
              </ColorPickButton>
            </div>
            <div>
              <label>Blur</label>
              <input type='number' min={0} max={50} value={shadowBlur} onChange={(e) => this.handleNumbersChange('shadowBlur', e)} />
            </div>
            <div>
              <label>Offset X</label>
              <input type='number' min={-100} max={100} value={shadowOffsetX} onChange={(e) => this.handleNumbersChange('shadowOffsetX', e)} />
            </div>
            <div>
              <label>Offset Y</label>
              <input type='number' min={-100} max={100} value={shadowOffsetY} onChange={(e) => this.handleNumbersChange('shadowOffsetY', e)} />
            </div>
          </div>
        </div>
        <div className='export-stage'>
          <h2>{`3. Save ${loadedPreviewIds.length > 1 ? 'them' : 'it'}`}</h2>
          <div className='previews'>
            {
              loadedPreviewIds.map((loadedPreviewId) => <div key={loadedPreviewId}>
                <ShadowedImage
                  imageElementId={loadedPreviewId}
                  backgroundColor={backgroundColor}
                  shadowColor={shadowColor}
                  shadowBlur={shadowBlur}
                  shadowOffsetX={shadowOffsetX}
                  shadowOffsetY={shadowOffsetY}
                />
              </div>)
            }
          </div>
        </div>
      </div>
    )
  }
}
