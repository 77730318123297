function readAs (file: File, as: 'DataURL' | 'Text' | 'ArrayBuffer') {
  if (!(file instanceof Blob)) {
    throw new TypeError('Must be a File or Blob')
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      resolve(e.target ? e.target.result : e.target)
    }
    reader.onerror = (e) => {
      reject(new Error(`Error reading${file.name}${e.target ? `: ${e.target.result}` : ''}`))
    }
    switch (as) {
      case 'ArrayBuffer':
        reader.readAsArrayBuffer(file)
        break
      case 'DataURL':
        reader.readAsDataURL(file)
        break
      case 'Text':
        reader.readAsText(file)
        break
      default:
        break
    }
  })
}

export function readAsDataURL (file: File) {
  return readAs(file, 'DataURL')
}

export function readAsText (file: File) {
  return readAs(file, 'Text')
}

export function readAsArrayBuffer (file: File) {
  return readAs(file, 'ArrayBuffer')
}
